import './App.css';

function App() {
  return (
    <div>
      <p className='Text'>Ciaran is my Boyfriend!</p>
    </div>
  );
}

export default App;
